import { Box, Button, MobileStepper, Paper, Typography, useTheme } from '@mui/material';
import './styles/OnboardingTour.css';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import CreateCottage from '../Assets/TourGifs/CreateCottage.gif';
import SendInvite from '../Assets/TourGifs/SendInvite.gif';
import AcceptInvite from '../Assets/TourGifs/AcceptInvite.gif';
import CreateItem from '../Assets/TourGifs/CreateItem.gif';
import PostMessage from '../Assets/TourGifs/PostMessage.gif';
import AddCar from '../Assets/TourGifs/AddCar.gif';
import logo from '../Assets/Cottage Buddy App Icon Without Cloud.png';
import { useNavigate } from 'react-router-dom';

const steps = [
    {
      label: 'Let\'s take a tour of Cottage Buddy',
      description: '',
      image: logo
    },
    {
      label: 'Creating a Cottage',
      description: 'Create a cottage instance which will become a hub for all information about your trip.',
      image: CreateCottage
    },
    {
      label: 'Inviting Friends',
      description: 'After creating a cottage, you can start inviting other user to join and collaborate in your cottage hub.',
      image: SendInvite
    },
    {
      label: 'Accepting Invites',
      description: 'After receiving an invite to a cottage, you can accept the invitation in your inbox on the landing page.',
      image: AcceptInvite
    },
    {
      label: 'Organizing Lists and Items',
      description: 'Create and assign list items, keeping your cottage trip prepared and organized. Collaborative list making can be turned on for everyone to add items for themselves.',
      image: CreateItem
    },
    {
      label: 'Posting Messages',
      description: 'Create posts to add to the cottage\'s message board. These can include important messages for all cottage-goers to read. When collaborative posting is turned on, any cottage-goer can post here.',
      image: PostMessage
    },
    {
      label: 'Coordinating Transportation',
      description: 'In the cars section of your cottage, people can volunteer as drivers for the trip. They can list their available seating, where other cottage-goers can request seats keeping everyone in sync.',
      image: AddCar
    },
    {
      label: 'Tour Complete',
      description: 'Let\'s get started',
      image: logo
    },
  ];

function OnboardingTour () {

    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = steps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };    
    
    //Navigation logic
    const navigate = useNavigate();

    const didClickGetStartedButton = () => {
        navigate('/login');
        window.scrollTo(0, 0);
    };

    //Modal logic
    const [contentContainerHeight, setContentContainerHeight] = useState<number>(0);
    const modalRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!modalRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            // Do what you want to do when the size of the element changes
            setContentContainerHeight(modalRef.current?.clientHeight ?? 0);
        });
        resizeObserver.observe(modalRef.current);
        return () => resizeObserver.disconnect(); // clean up 
    }, [modalRef.current]);

    return (
        <div className='Onboardingtour-container' ref={modalRef}>
            <Paper
                square
                elevation={0}
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  height: '50px',
                  fontFamily: 'monospace',
                  fontWeight: 'bold', 
                  fontSize: '20px',
                  backgroundColor: '#F6F6DB'
                }}
            >
                <div>{steps[activeStep].label}</div>
            </Paper>
            <Box>
                { steps[activeStep].image &&
                  <img
                    src={steps[activeStep].image} 
                    alt="Cottage Tour Video"
                    style={{
                      maxHeight: contentContainerHeight*0.7,
                      maxWidth: '100%',
                      height: 'auto',
                      width: 'auto',
                      flexGrow: 1,
                      borderRadius: '10px',
                      border: '3px solid #1A3B0E'
                    }}
                  />
                }
                <div 
                  style={{
                    height: contentContainerHeight*0.2,
                    width: '100%',
                    fontFamily: 'monospace',
                    marginBottom: '5px',
                    flexGrow: '1',
                    paddingTop: '10px'
                  }}
                >
                  { activeStep == 7 ?
                    <div>
                      <Button
                          variant="contained"
                          sx={{
                              margin: '10px',
                              backgroundColor: '#1A3B0E',
                              '&:hover': {
                                  backgroundColor: '#102408'
                              }
                          }}
                          onClick={didClickGetStartedButton}
                      >
                          Let's Get Started
                      </Button>
                    </div>
                    :
                    <div>
                      {steps[activeStep].description}
                    </div>
                  }
                </div>
            </Box>
            <MobileStepper
                variant="text"
                steps={maxSteps}
                position="bottom"
                sx={{
                  margin: '5px',
                  fontFamily: 'monospace',
                  backgroundColor: '#F6F6DB'
                }}
                activeStep={activeStep}
                nextButton={
                  <Button
                      size="small"
                      onClick={handleNext}
                      disabled={activeStep === maxSteps - 1}
                      sx={{ color: '#1A3B0E' }} 
                  >
                      Next
                      {theme.direction === 'rtl' ? (
                      <KeyboardArrowLeft />
                      ) : (
                      <KeyboardArrowRight />
                      )}
                  </Button>
                }
                backButton={
                  <Button size="small" onClick={handleBack} sx={{ color: '#1A3B0E' }} disabled={activeStep === 0}>
                      {theme.direction === 'rtl' ? (
                      <KeyboardArrowRight />
                      ) : (
                      <KeyboardArrowLeft />
                      )}
                      Back
                  </Button>
                }
            />
        </div>
    )
}

export default OnboardingTour;