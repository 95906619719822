import * as React from 'react';
import './styles/CottageBuddyHeader.css';
import logo from '../Assets/CircularLogo.png';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { auth } from '../Firebase/firebase';
import { signOut } from "firebase/auth";

function CottageBuddyHeader( {isUserLoggedIn} : {isUserLoggedIn: boolean} ) {

  const pages = ['Home'];
  
  const settings = ['Profile', 'Logout'];

  //Open and close menu logic
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  //Navigation logic
  const navigate = useNavigate()
  const routeToPage = (page: string) => {
    page = page.toLowerCase();
    navigate('/' + page);
    window.scrollTo(0, 0);
  }; 

  const userMenuOptionClicked = (option: string) => {
    option = option.toLowerCase();
    if (option === "logout") {
      logOutButtonClicked()
    } else if (option === "profile") {
      profileButtonClicked()
    }
  };

  //Auth button logic
  function loginButtonClicked() {
    // signInWithPopup(auth, provider);
    routeToPage("login")
  }
  function logOutButtonClicked() {
    signOut(auth).then(() => {
      // Sign-out successful.
     }).catch((error) => {
       // An error happened.
     });
  }
  
  const profileButtonClicked = () => {

    navigate('/profile')

  }

  const renderAuthButton = () => {
    if (!isUserLoggedIn) {
      return (
        <Button onClick={() => {handleCloseNavMenu();loginButtonClicked()}} color="inherit">
          Login
        </Button>
      )
    } else {
      return (
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar></Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px', zIndex: '1000000000000' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={() => {handleCloseUserMenu();userMenuOptionClicked(setting);}}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )
    }
  }

  return (
    <AppBar position="static" className="Cotttage-buddy-app-bar">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img src={logo} height="40" width="40" alt="Logo" className="Toolbar-logo" />
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Cottage Buddy
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
                zIndex: '1000000000000'
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={() => {handleCloseNavMenu();routeToPage(page);}}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Cottage Buddy
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => {
                  handleCloseNavMenu();
                  routeToPage(page);
                  }
                }
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {renderAuthButton()}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default CottageBuddyHeader