import './styles/CottageBuddyFooter.css'
import { useNavigate } from 'react-router-dom';

function CottageBuddyFooter() {

  //Navigation logic
  const navigate = useNavigate()

  const routeToAbout = () => {
    navigate('/about');
    window.scrollTo(0, 0);
  };

  const routeToContactUs = () => {
    navigate('/contact');
    window.scrollTo(0, 0);
  };

  const routeToFaq = () => {
    navigate('/faq');
    window.scrollTo(0, 0);
  }

  const routeToSocials = () => {
    navigate('/socials');
    window.scrollTo(0, 0);
  };

    return (
        <div style={{ lineHeight: 1.5 }}>
            <div className='Footer-item' onClick={routeToAbout}>About</div>
            <div className='Footer-item' onClick={routeToContactUs}>Contact</div>
            <div className='Footer-item' onClick={routeToFaq}>FAQ</div>
            <div className='Footer-item' onClick={routeToSocials}>Socials</div>
            <div className='Footer-bar' />
            <div style={{ alignItems: 'center' }}>&copy; 2025 Philip D'Aloia</div>
        </div>
    );

}

export default CottageBuddyFooter;