import { DocumentReference, addDoc, collection, deleteDoc, doc, getDocs, orderBy, query } from "firebase/firestore";
import { Post } from "../Models/Post";
import { db } from "../Firebase/firebase";
import { getAttendee } from "./UserService";

async function getPosts(cottageId: string) : Promise<Post[] | null> {

    var postsRef = collection(db, "cottages", cottageId, "posts");
    const postsQuery = query(postsRef, orderBy("dateCreated", "desc"));

    const postsSnapshot = await getDocs(postsQuery);

    var promises: any[] = [];
    postsSnapshot.forEach((doc) => {
        let id: string = doc.id;
        let postText: string = doc.data().text;
        let postDate: Date = doc.data().dateCreated.toDate();
        let poster: DocumentReference = doc.data().poster;

        if (poster) {
            promises.push(getAttendee(poster)
            .then((poster) => {
                if (poster) {
                    let post = new Post(id, poster, postText, [], postDate);
                    return post;
                }
            })
            )
        }
    });

    return Promise.all(promises);

}

async function uploadPostToFirebase(cottageId: string, userId: string, postText: string, dateCreated: Date) {

    const postsCollectionRef = collection(db, "cottages", cottageId, "posts");

    const posterAttendeeRef = doc(db, "users", userId);

    await addDoc(postsCollectionRef, {
        "poster": posterAttendeeRef,
        "text": postText,
        "dateCreated": dateCreated
    })

}

async function deletePostFromFirestore(cottageId: string, postId: string) {

    const postRef = doc(db, "cottages", cottageId, "posts", postId);

    await deleteDoc(postRef);

}

export { getPosts, uploadPostToFirebase, deletePostFromFirestore };