import './styles/About.css'
import logo from '../Assets/Cottage Buddy App Icon.png';
import iphonecreatecottage from '../Assets/iphonecreatecottage.png';
import iphonelists from '../Assets/iphonelists.png'
import iphoneposts from '../Assets/iphoneposts.png'
import iphonetripinfo from '../Assets/iphonetripinfo.png'
import { Divider } from '@mui/material';

function About() {
    return (
        <div className='About-container'>
            <div className='About-mission-statement'>
                <div className='Mission-statement-text-section'>
                    <div className='Mission-statement-title'>
                        Stress Free Collaboration
                    </div>
                    <div className='Mission-statement-content'>
                        Cottage Buddy is a collaborative app, aiming to make every step of your trip planning process easier. With so many moving parts in trip preparation, Cottage Buddy aims to create visibility and participation with everyone involved
                    </div>
                </div>
                <div className='Mission-statement-image'>
                    <img src={logo} className="Homepage-logo" alt="logo" />
                </div>
            </div>
            <div className='Subsection'>
                <div className='Subsection-image'>
                    <img src={iphonecreatecottage} className="Subsection-logo" alt="logo" />
                </div>
                <div className='Subsection-text-section'>
                    <div className='Subsection-text-title-right-adjusted'>
                        Loosen up
                    </div>
                    <div className='Subsection-text-subtext-right-adjusted'>
                        Making the process of going on a stress-free getaway, stress-free
                    </div>
                </div>
            </div>
            <Divider/>
            <div className='Subsection'>
                <div className='Subsection-text-section'>
                    <div className='Subsection-text-title-left-adjusted'>
                        Collaborate
                    </div>
                    <div className='Subsection-text-subtext-left-adjusted'>
                        Invite your fellow cottage-goers to your trip
                    </div>
                </div>
                <div className='Subsection-image'>
                    <img src={iphoneposts} className="Subsection-logo" alt="logo" />
                </div>
            </div>
            <Divider/>
            <div className='Subsection'>
                <div className='Subsection-image'>
                    <img src={iphonelists} className="Subsection-logo" alt="logo" />
                </div>
                <div className='Subsection-text-section'>
                    <div className='Subsection-text-title-right-adjusted'>
                        Organize
                    </div>
                    <div className='Subsection-text-subtext-right-adjusted'>
                        Everyone can contribute
                    </div>
                </div>
            </div>
            <Divider/>
            <div className='Subsection'>
                <div className='Subsection-text-section'>
                    <div className='Subsection-text-title-left-adjusted'>
                        Cottage Buddy
                    </div>
                    <div className='Subsection-text-subtext-left-adjusted'>
                        For all your planning needs
                    </div>
                </div>
                <div className='Subsection-image'>
                    <img src={iphonetripinfo} className="Subsection-logo" alt="logo" />
                </div>
            </div>
            <Divider/>
            <div className='Appstore-section'>
                <a href="https://apps.apple.com/us/app/cottage-buddy/id6475366100?itscg=30200&itsct=apps_box_badge&mttnsubad=6475366100" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', width: '100%' }}>
                    <img src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/en-us?releaseDate=1705276800" alt="Download on the App Store" style={{ width: '150px', verticalAlign: 'middle', objectFit: 'contain' }} />
                </a>
            </div>
        </div>
    );
}

export default About;