import './styles/LoginPage.css';
import logo from '../Assets/Cottage Buddy App Icon.png';
import { Button, Divider } from '@mui/material';
import { signInWithPopup } from 'firebase/auth';
import { appleProvider, auth, provider } from '../Firebase/firebase';
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';

function LoginPage() {

    const signInWithGooglePressed = () => {
        signInWithPopup(auth, provider)
        .catch((error) => {
            //no-op
        });
    }

    const signInWithApplePressed = () => {
        signInWithPopup(auth, appleProvider)
        .then((result) => {
            //no-op
        })
        .catch((error) => {
            //no-op
        })
    }
   
   return (
       <div className='Loginpage-container'>
           <h1 style={{ color: '#103803' }} className="Loginpage-title">
                Sign In To Cottage Buddy
            </h1>
            <div className='Loginpage-image'>
                <img src={logo} className="Loginpage-logo" alt="logo" />
            </div>
            <Divider sx={{ width: '90%', marginBottom: '20px' }}/>
            <Button
                onClick={signInWithGooglePressed}
                sx={{
                    color: '#103803',
                    borderRadius: '500px',
                    backgroundColor: '#A7D489',
                    '&:hover': {
                        color: '#FFFBF0',
                        backgroundColor: '#102408'
                    },
                    marginBottom: '20px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '300px'
                }}
            >
                <div style={{ width: '75%' }}>
                    Sign In With Google
                </div>
                <div style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <GoogleIcon/>
                </div>
            </Button>
            <Button
                onClick={signInWithApplePressed}
                sx={{
                    color: '#103803',
                    borderRadius: '500px',
                    backgroundColor: '#A7D489',
                    '&:hover': {
                        color: '#FFFBF0',
                        backgroundColor: '#102408'
                    },
                    marginBottom: '20px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '300px'
                }}
            >
                <div style={{ width: '75%' }}>
                    Sign In With Apple
                </div>
                <div style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <AppleIcon/>
                </div>
            </Button>
       </div>
   );

}

export default LoginPage;