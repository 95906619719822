import './styles/CarsTab.css'
import { Car } from "../Models/Car";
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EmptyTab from './EmptyTab';
import { useState } from 'react';
import { AddCircle } from '@mui/icons-material';
import { NumberFieldInput } from '../Shared/styles/NumberField';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import { Attendee } from '../Models/Attendee';
import { getAttendeeById } from '../Services/UserService';

interface CarsTabProps {
    cars: Car[] | null
    loggedInUserId: string
    deleteCar: () => Promise<void>
    addCar: (numberOfSeats: number) => Promise<void>
    createRequest: (driverId: string) => Promise<void>
    leaveCar: (driverId: string) => Promise<void>
    acceptPassengerRequest: (passengerId: string) => Promise<void>
    rejectPassengerRequest: (passengerId: string) => Promise<void>
    isLoadingCarButtonFlags: boolean[]
    setCarTabButtonLoadingFlag: (index: number, value: boolean) => void
}

function CarsTab( { cars, loggedInUserId, deleteCar, addCar, createRequest, leaveCar, acceptPassengerRequest, rejectPassengerRequest, isLoadingCarButtonFlags, setCarTabButtonLoadingFlag } : CarsTabProps ) {

    //state
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [openLeaveCarConfirm, setOpenLeaveCarConfirm] = useState(false);
    const [openInvitesInbox, setOpenInvitesInbox] = useState(false);
    const [numberOfSeatsInNewCar, setNumberOfSeatsInNewCar] = useState<number | null>(null);
    const [isLoadingPassengerRequest, setIsLoadingPassengerRequests] = useState<boolean>(false);
    const [passengerRequesters, setPassengerRequesters] = useState<Attendee[]>([]);
    const [deleteCarDialogIndex, setDeleteCarDialogIndex] = useState<number | null>();
    const [leaveCarDialogIndex, setLeaveCarDialogIndex] = useState<number | null>(null);

    //event handlers
    const didClickDeleteCar = (index: number) => {
        setDeleteCarDialogIndex(index);
        setOpenDeleteConfirm(true);
    }

    const didClickInviteInbox = () => {
        setOpenInvitesInbox(true); 
        getPassengerRequesters();
    }

    const handleClickConfirmDeleteItems = () => {
        setOpenDeleteConfirm(false);
        if (deleteCarDialogIndex) {
            setCarTabButtonLoadingFlag(deleteCarDialogIndex, true);
        }
        deleteCar()
        .then(() => {
            setDeleteCarDialogIndex(null);
        })
        .catch(() => {
            if (deleteCarDialogIndex) {
                setCarTabButtonLoadingFlag(deleteCarDialogIndex, true);
            }
            setDeleteCarDialogIndex(null);
        })
    };

    const handleClickCancelDeleteItems = () => {
        setDeleteCarDialogIndex(null);
        setOpenDeleteConfirm(false);
    };

    const handleClickCloseInviteInbox = () => {
        setOpenInvitesInbox(false);
    }

    const didClickAddCar = () => {
        if (numberOfSeatsInNewCar) {
            addCar(numberOfSeatsInNewCar);
        }
    }

    const handleNumberOfSeatsChange = (numberOfSeats: string) => {
        if (numberOfSeats === "") {
            setNumberOfSeatsInNewCar(null);
        }
        else if (+numberOfSeats < 0) {
            setNumberOfSeatsInNewCar(0);
        }
        else if (+numberOfSeats > 100) {
            setNumberOfSeatsInNewCar(100);
        }
        else {
            setNumberOfSeatsInNewCar(+numberOfSeats);
        }
    }

    const didClickRequestSeatInCar = (carDriverId: string, index: number) => {
        setCarTabButtonLoadingFlag(index, true);
        createRequest(carDriverId)
        .catch((err) => {
            setCarTabButtonLoadingFlag(index, false);
        });
    }

    const didClickAcceptPassenger = (passengerId: string) => {
        setIsLoadingPassengerRequests(true);
        acceptPassengerRequest(passengerId)
        .then(() => {
            setOpenInvitesInbox(false);
        });
    }

    const didClickDeclinePassenger = (passengerId: string) => {
        setIsLoadingPassengerRequests(true);
        rejectPassengerRequest(passengerId)
        .then(() => {
            setOpenInvitesInbox(false);
        });
    }

    const didClickLeaveCar = (carDriverId: string, index: number) => {
        setLeaveCarDialogIndex(index);
        setOpenLeaveCarConfirm(true);
    }
    
    const handleClickConfirmLeaveCar = () => {

        if (leaveCarDialogIndex !== null && cars !== null) {
            const carDriverId = cars[leaveCarDialogIndex].driver.firebaseUserID;

            setCarTabButtonLoadingFlag(leaveCarDialogIndex, true);
            leaveCar(carDriverId)
            .then(() => {
                setCarTabButtonLoadingFlag(leaveCarDialogIndex, false);
                setOpenLeaveCarConfirm(false);
            })
            .catch((err) => {
                setLeaveCarDialogIndex(null);
                setCarTabButtonLoadingFlag(leaveCarDialogIndex, false);
                setOpenLeaveCarConfirm(false);
            });
        }

    }

    const handleClickCancelLeaveCar = () => {
        setLeaveCarDialogIndex(null);
        setOpenLeaveCarConfirm(false);
    }

    //helper functions
    const canAddNewCar = (): boolean => {
        if (cars) {
            if (cars.find(car => car.driver.firebaseUserID === loggedInUserId)) {
                return false;
            }
            return true;
        }
        else {
            return false;
        }
    }

    const getCarPassengerRequests = (): string[] => {
        if (cars) {
            const carDriven = cars.find(car => car.driver.firebaseUserID === loggedInUserId)
            if (carDriven) {
                return carDriven.requests;
            }
            else {
                return [];
            }
        }
        else {
            return [];
        }
    }

    const getPassengerRequesters = () => {
        setIsLoadingPassengerRequests(true);
        setPassengerRequesters([]);

        const carRequesterIds: string[] = getCarPassengerRequests();
        var carRequesters: Attendee[] = [];

        var promises: Promise<any>[] = [];
        carRequesterIds.forEach(requesterId => {
            promises.push(
                getAttendeeById(requesterId)
                .then((requester) => {
                    if (requester) {
                        carRequesters.push(requester);
                    }
                })
            )
        });
        
        Promise.all(promises)
        .then(() => {
            setPassengerRequesters(carRequesters);
            setIsLoadingPassengerRequests(false);
        })

    }

    return (
        <div className='Carstab-container'>
            <div className='Carstab-title'>
                Cars
            </div>
            { canAddNewCar() &&
                <>
                <div className='Carstab-add-car-accordion'>
                    <Accordion sx={{ backgroundColor: '#A7D489' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <div className='Carstab-accordion-summary-container'>
                                <Typography align="center" sx={{ width: '100%', fontFamily: 'monospace' }}>Add yourself as a driver!</Typography>
                                <AddCircle />
                            </div>
                        </AccordionSummary>
                        <AccordionDetails sx={{ fontFamily: 'monospace' }}>
                            <Grid container direction={"column"} spacing={2}>
                                <Grid item>
                                    <TextField
                                        type='number'
                                        InputProps={{
                                            inputProps: { min: 1, max: 100 }
                                        }}
                                        sx={NumberFieldInput}
                                        inputProps={{ autoComplete: 'off' }}
                                        InputLabelProps={{ style: { fontFamily: 'monospace', color: '#73925B' } }}
                                        className='CreateCottage-input'
                                        label="Seats"
                                        onChange={(e) => handleNumberOfSeatsChange(e.target.value)}
                                        value={numberOfSeatsInNewCar} />
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            width: 300,
                                            backgroundColor: '#1A3B0E',
                                            '&:hover': {
                                                backgroundColor: '#102408'
                                            }
                                        }}
                                        onClick={didClickAddCar}>
                                        Add Car
                                    </Button>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </div><Divider style={{ marginBottom: '20px' }} />
                </>
            }
            {
                cars ?
                <div>
                    {getCarPassengerRequests().length > 0 &&
                        <div onClick={didClickInviteInbox} className='Carstab-passenger-request-tile'>
                            <p style={{ padding: '5px' }}>You have a passenger request!</p>
                            <MarkunreadMailboxIcon style={{ padding: '5px' }} />
                        </div>
                    }
                    { cars.length > 0 ?
                        <div>
                            {cars.map((car, index) => (
                                <div className='Carstab-accordion-container'>
                                    <Accordion sx={{ backgroundColor: '#A7D489' }}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                            <div className='Carstab-accordion-summary-container'>
                                                <Typography align="center" sx={{width: '100%', fontFamily: 'monospace'}}>{car.driver.name}'s car</Typography>
                                                <DirectionsCarIcon/>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ fontFamily: 'monospace' }}>
                                            <div>Number of seats: {car.numberOfSeats.toString()}</div>
                                            <div style={{ marginBottom: '10px' }}>Number of passengers: {car.passengers.length}</div>
                                            { isLoadingCarButtonFlags[index] ?
                                                <div>
                                                    <CircularProgress color='success' />
                                                </div>
                                                :
                                                <div>
                                                    {/* Button for user to delete their car */}
                                                    {car.driver.firebaseUserID === loggedInUserId &&
                                                        <div>
                                                            <Button
                                                                variant="contained"
                                                                sx={{
                                                                    width: 300,
                                                                    backgroundColor: '#1A3B0E',
                                                                    '&:hover': {
                                                                        backgroundColor: '#102408'
                                                                    }
                                                                }}
                                                                onClick={() => didClickDeleteCar(index)}>
                                                                Delete Car
                                                            </Button>
                                                        </div>
                                                    }
                                                    {/* Button section for seat requests */}
                                                    {(car.driver.firebaseUserID !== loggedInUserId && !car.passengers.includes(loggedInUserId)) && canAddNewCar() &&
                                                        <div>
                                                           {/* Button for user to request a seat in a car */}
                                                            { !car.requests.includes(loggedInUserId) &&
                                                                <div>
                                                                    <Button
                                                                        variant="contained"
                                                                        sx={{
                                                                            width: 300,
                                                                            backgroundColor: '#1A3B0E',
                                                                            '&:hover': {
                                                                                backgroundColor: '#102408'
                                                                            }
                                                                        }}
                                                                        onClick={() => {didClickRequestSeatInCar(car.driver.firebaseUserID, index)}}>
                                                                        Request Seat
                                                                    </Button>
                                                                </div>
                                                            }
                                                            {/* Disabled button for requesting a car seat */}
                                                            { car.requests.includes(loggedInUserId) &&
                                                                <div>
                                                                    <Button
                                                                        variant="contained"
                                                                        sx={{
                                                                            width: 300,
                                                                            backgroundColor: '#1A3B0E',
                                                                            '&:hover': {
                                                                                backgroundColor: '#102408'
                                                                            }
                                                                        }}
                                                                        disabled
                                                                    >
                                                                        Seat Requested
                                                                    </Button>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                    {car.passengers.includes(loggedInUserId) &&
                                                        <div>
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                width: 300,
                                                                backgroundColor: '#1A3B0E',
                                                                '&:hover': {
                                                                    backgroundColor: '#102408'
                                                                }
                                                            }}
                                                            onClick={() => {didClickLeaveCar(car.driver.firebaseUserID, index)}}>
                                                            Leave Car
                                                        </Button>
                                                    </div>
                                                    }
                                                </div>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            ))}
                        </div>
                        :
                        <EmptyTab labelText={'No drivers yet...'}/>
                    }
                </div>
                :
                <div>
                    <CircularProgress color='success' />
                </div>
            }
            <div>
                <Dialog
                    open={openDeleteConfirm}
                    onClose={handleClickCancelDeleteItems}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{ zIndex:'10000000000000000000' }}
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Remove car?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you'd like to remove your car from the trip?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClickCancelDeleteItems}>Cancel</Button>
                        <Button onClick={handleClickConfirmDeleteItems} autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div>
                <Dialog
                    open={openLeaveCarConfirm}
                    onClose={handleClickCancelLeaveCar}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{ zIndex:'10000000000000000000' }}
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Leave car?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you'd like to leave this car?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClickCancelLeaveCar}>Cancel</Button>
                        <Button onClick={handleClickConfirmLeaveCar} autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div>
                <Dialog
                    open={openInvitesInbox}
                    onClose={handleClickCloseInviteInbox}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{ zIndex:'10000000000000000000' }}
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Car Passenger Requests"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            { isLoadingPassengerRequest ?
                                <div style={{ width: '100%', alignContent: 'center' }}>
                                    <CircularProgress color='success' />
                                </div>
                                :
                                <div>
                                    { passengerRequesters.map((requester) => (
                                        <div className='Carstab-passenger-requests-row'>
                                            <div style={{ padding: '10px' }}>{requester.name}</div>
                                            <div className='Carstab-passenger-request-row-buttons'>
                                                <div>
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            //width: 300,
                                                            backgroundColor: '#1A3B0E',
                                                            '&:hover': {
                                                                backgroundColor: '#102408'
                                                            }
                                                        }}
                                                        onClick={() => {didClickAcceptPassenger(requester.firebaseUserID)}}>
                                                        Accept
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            //width: 300,
                                                            backgroundColor: '#7d1e23',
                                                            '&:hover': {
                                                                backgroundColor: '#571518'
                                                            },
                                                            marginLeft: '5px'
                                                        }}
                                                        onClick={() => {didClickDeclinePassenger(requester.firebaseUserID)}}>
                                                        Decline
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClickCloseInviteInbox}>Close</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )

}

export default CarsTab;