import './App.css';
import * as React from 'react';
import { auth } from './Firebase/firebase';
import { onAuthStateChanged } from "firebase/auth";
import { Routes , Route, Navigate } from "react-router-dom" 
import CottageBuddyHeader from './Components/CottageBuddyHeader';
import CottageBuddyFooter from './Components/CottageBuddyFooter';
import Home from './Pages/Home'
import Cottages from './Pages/Cottages';
import Cottage from './Pages/Cottage';
import Profile from './Pages/Profile';
import { checkForUserDocument, createUserDocument } from './Services/UserService';
import ContactUs from './Pages/ContactUs';
import LoginPage from './Pages/LoginPage';
import { CircularProgress } from '@mui/material';
import SocialsPage from './Pages/SocialsPage';
import FaqPage from './Pages/FaqPage';

function App() {

  const [isUserLoggedIn, setIsUserLoggedIn] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsUserLoggedIn(true);
        checkForUserDocument(user.uid)
        .then((found) => {
        if (found) {
          // do nothing right now
        }
        else {
          createUserDocument(user.uid, user.email ?? "", user.displayName ?? "");
        }
       });
      } else {
        setIsUserLoggedIn(false);
      }
    });
  }, []);

  return (
    <div className="App">
      { isUserLoggedIn !== null ?
      <div>
        <header className='App-header'>
          <CottageBuddyHeader isUserLoggedIn={isUserLoggedIn ?? false}/>
        </header>
        <div className="App-body">
          <Routes>
            <Route path="/" element={ isUserLoggedIn ? <Navigate replace to="/cottages"/> : <Home title='Welcome To Cottage Buddy' includeTourAndSignInLinks={true}/> } />
            <Route path="/home" element={ isUserLoggedIn ? <Navigate replace to="/cottages"/> : <Home title='Welcome To Cottage Buddy' includeTourAndSignInLinks={true}/> } />
            <Route path="/login" element={ isUserLoggedIn ? <Navigate replace to="/cottages"/> : <LoginPage/> } />
            <Route path="/about" element={ <Home title='About Cottage Buddy' includeTourAndSignInLinks={false}/>} />
            <Route path="/cottages" element={<Cottages/>} />
            <Route path="/cottage/:id/:tabUrlParam?" element={<Cottage/>} />
            <Route path="/profile" element={ isUserLoggedIn ? <Profile/> : <Navigate replace to="/login"/> } />
            <Route path="/contact" element={<ContactUs/>} />
            <Route path="/faq" element={<FaqPage/>} />
            <Route path="/socials" element={<SocialsPage/>} />
            <Route path="*" element={<Navigate replace to="/home"/>} />
          </Routes>
        </div>
        <footer className='App-footer'>
          <CottageBuddyFooter />
        </footer>
      </div>
      :
      <div style={{ width: '100%', textAlign: 'center' }}>
          <CircularProgress color='success' />
      </div>
      }
    </div>
  )
}

export default App;
