import './styles/EmptyTab.css'

interface EmptyTabProps {
    labelText: string
}

function EmptyTab( { labelText } : EmptyTabProps ) {

    return (
        <div className="Emptytab-image-container">
            <div>
                <img src={require('../Assets/EmptySign.png')} className="Emptytab-image" alt='Empty Sign' />
            </div>
            <div className='Emptytab-no-invites-label'>
                {labelText}
            </div>
        </div>
    )

}

export default EmptyTab;