import { auth, db } from '../Firebase/firebase';
import { DocumentData, DocumentReference, addDoc, arrayRemove, arrayUnion, collection, deleteDoc, deleteField, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { CottageInfo } from '../Models/CottageInfo';
import { Attendee } from '../Models/Attendee';
import { getAttendee } from './UserService';

async function getCottages(userId: string) : Promise<CottageInfo[]> {

    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        let cottageIds: string[] = docSnap.data().cottageIDs;

        var cottagesToReturn: CottageInfo[] = [];

        for (const cottageId of cottageIds) {

            const cottageRef = doc(db, "cottages", cottageId);
            try {
                const cottageSnapshot = await getDoc(cottageRef);
                if (cottageSnapshot.exists()) {
    
                    let cottageName = cottageSnapshot.data().tripName;
                    let address = cottageSnapshot.data().address;
                    let startDate = cottageSnapshot.data().startDate;
                    let endDate = cottageSnapshot.data().endDate;
                    let collaborativeGroceries = cottageSnapshot.data().collaborativeGroceries;
                    let collaborativePosts = cottageSnapshot.data().collaborativePosts;
    
                    //TODO: - Add organizer attendee here, is set to a blank attendee model currently
                    let cottageInfo = new CottageInfo(cottageId, cottageName, new Attendee("", ""), startDate.toDate(), endDate.toDate(), collaborativeGroceries, collaborativePosts, address);
                    cottagesToReturn.push(cottageInfo);
    
                }
            } catch(err) {
                //no-op
            }
            
        };

        cottagesToReturn.sort(function(a,b){
            return b.startDate.getTime() - a.startDate.getTime();
        });

        return cottagesToReturn;
    } else {
        // docSnap.data() will be undefined in this case
        return [];
    }

}

async function createCottage(name: string, address: string, startDate: Date, endDate: Date, collaborativeGroceries: boolean, collaborativePosts: boolean) : Promise<CottageInfo | null> {

    const userId = auth.currentUser!.uid;

    //get the user document
    const organizerDocRef = doc(db, "users", userId);

    const organizerSnapshot = await getDoc(organizerDocRef)

    if (organizerSnapshot.exists()) {
        const organizer = new Attendee(organizerSnapshot.data().fullName, userId);

        const newCottageDocRef = await addDoc(collection(db, "cottages"), {
            "address" : address,
            "organizer" : organizerDocRef,
            "tripName" : name,
            "startDate" : startDate,
            "endDate" : endDate,
            "collaborativeGroceries" : collaborativeGroceries,
            "collaborativePosts" : collaborativePosts
        });
    
        const newCottageAttendeesRef = collection(newCottageDocRef, "attendees")
        const organizerAttendeeDocRef = doc(newCottageAttendeesRef, userId);
        await setDoc(organizerAttendeeDocRef, {
            "userDoc": organizerDocRef
        })
    
        await updateDoc(organizerDocRef, {
            "cottageIDs": arrayUnion(newCottageDocRef.id)
        })
    
        let newCottageInfo = new CottageInfo(newCottageDocRef.id, name, organizer, startDate, endDate, collaborativeGroceries, collaborativePosts, address);

        return newCottageInfo;
    }
    else {
        //handle here
        return null;
    }

}

async function getCottageInfo(cottageId: string) : Promise<CottageInfo | null> {

    const cottageDocRef = doc(db, "cottages", cottageId);
    const cottageSnapshot = await getDoc(cottageDocRef);

    if (cottageSnapshot.exists()) {

        let cottageName = cottageSnapshot.data().tripName;
        let address = cottageSnapshot.data().address;
        let startDate = cottageSnapshot.data().startDate;
        let endDate = cottageSnapshot.data().endDate;
        let collaborativeGroceries = cottageSnapshot.data().collaborativeGroceries;
        let collaborativePosts = cottageSnapshot.data().collaborativePosts;

        let organizerDocRef: DocumentReference<DocumentData, DocumentData> = cottageSnapshot.data().organizer;
        let organizerSnapshot = await getDoc(organizerDocRef);

        let organizer = new Attendee("", "");
        if(organizerSnapshot.exists()) {
            organizer.name = organizerSnapshot.data().fullName;
            organizer.firebaseUserID = organizerSnapshot.id;
        } 
        else {
            return null;
        }

        let cottageInfo = new CottageInfo(cottageId, cottageName, organizer, startDate.toDate(), endDate.toDate(), collaborativeGroceries, collaborativePosts, address);

        return cottageInfo;

    }
    else {
        return null;
    }

}

async function editCottageInfo(info: CottageInfo) : Promise<CottageInfo | null> {

    const cottageDocRef = doc(db, "cottages", info.cottageID);
    await updateDoc(cottageDocRef, {
        tripName: info.cottageName,
        address: info.address,
        startDate: info.startDate,
        endDate: info.endDate,
        collaborativeGroceries: info.isGroceriesCollaborative,
        collaborativePosts: info.isPostsCollaborative
    })

    return info;

}

async function getAttendeesForCottage(cottageId: string) : Promise<Attendee[]> {
    const attendeesSnapshot = await getDocs(collection(db, "cottages", cottageId, "attendees"));

    var promises: any[] = [];
    attendeesSnapshot.forEach((attendeeDoc) => {
        let attendeeRef: DocumentReference = attendeeDoc.data().userDoc;

        if(attendeeRef) {
            promises.push(getAttendee(attendeeRef)
            .then((attendee) => {
                if (attendee) {
                    return attendee;
                }
            })
            )
        }
    });

    return Promise.all(promises)
    .then((attendees) => {
        return attendees.filter(function(attendee){ return attendee !== undefined })
    });

}

async function getInvitedEmails(cottageId: string): Promise<string[]> {
    const cottageDocRef = doc(db, "cottages", cottageId);
    const cottageSnapshot = await getDoc(cottageDocRef);
            
    let invitedEmails: string[] = [];
    if (cottageSnapshot.exists()) {
        invitedEmails = cottageSnapshot.data().invitedEmails;
    }

    return invitedEmails;
}

async function removeAttendeeFromFirestore(cottageId: string, attendeeId: string): Promise<null> {

    const userDoc = doc(db, "users", attendeeId);

    //Remove all groceries assigned to user
    const groceriesCollection = collection(db, "cottages", cottageId, "groceries");
    const groceriesQuery = query(groceriesCollection, where("assignedTo", "==", userDoc));
    const groceriesQuerySnapshot = await getDocs(groceriesQuery);
    groceriesQuerySnapshot.forEach(async (doc) => {
        let docReference = doc.ref;
        await updateDoc(docReference, {
            "assignedTo": deleteField()
        });
    });

    //Remove any cars driven by user
    //Remove all passengers/car requests by user
    const carsCollection = collection(db, "cottages", cottageId, "cars");
    const driverDocument = doc(carsCollection, attendeeId);
    const passengersQuery = query(carsCollection, where("passengers", "array-contains", attendeeId));
    const requestsQuery = query(carsCollection, where("requests", "array-contains", attendeeId));

    await deleteDoc(driverDocument);

    const passengersSnapshot = await getDocs(passengersQuery);
    passengersSnapshot.forEach(async (doc) => {
        let docReference = doc.ref;
        await updateDoc(docReference, {
            "passengers": arrayRemove(attendeeId)
        });
    });

    const requestsSnapshot = await getDocs(requestsQuery);
    requestsSnapshot.forEach(async (doc) => {
        let docReference = doc.ref;
        await updateDoc(docReference, {
            "requests": arrayRemove(attendeeId)
        });
    });

    //Remove user from cottage attendees
    const attendeeCottageDoc = doc(db, "cottages", cottageId, "attendees", attendeeId);
    await deleteDoc(attendeeCottageDoc);

    //Remove cottage from user doc
    const attendeeUserDoc = doc(db, "users", attendeeId);
    await updateDoc(attendeeUserDoc, {
        "cottageIDs": arrayRemove(cottageId)
    });

    return null;

}

async function deleteInviteFromFirestore(cottageId: string, email: string): Promise<null> {

    const usersCollection = collection(db, "users")

    //remove the invite from the user's doc OR if there is no user doc, remove from pending invites
    const userDocQuery = query(usersCollection, where("email", "==", email));
    const userDocs = await getDocs(userDocQuery);

    if (userDocs.size === 1) {
        let userDocRef = userDocs.docs[0].ref;
        updateDoc(userDocRef, {
            "invitedCottageIDs": arrayRemove(cottageId)
        });
    }
    else if (userDocs.size > 1) {
        //erm wtf
    }
    else {
        //need to remove from uncreated doc of email
        const uncreatedDocRef = doc(db, "uncreated", email);
        await updateDoc(uncreatedDocRef, {
            "pendingInvites": arrayRemove(cottageId)
        });
    }

    //remove from cottage
    const cottageDocRef = doc(db, "cottages", cottageId);
    await updateDoc(cottageDocRef, {
        "invitedEmails": arrayRemove(email)
    });

    return null;

}

async function sendInviteToFirebase(cottageId: string, email: string): Promise<void> {

    const usersCollection = collection(db, "users");
    const userDocQuery = query(usersCollection, where("email", "==", email));

    const userDocs = await getDocs(userDocQuery);

    if (userDocs.size === 1) {
        //send invite to user
        const userDoc = userDocs.docs[0];
        const cottageIds: string[] = userDoc.data().cottageIDs;

        if (cottageIds.includes(cottageId)) {
            throw new Error("userInCottage");
        }
        else {
            const userDocRef = userDoc.ref;
            await updateDoc(userDocRef, {
                "invitedCottageIDs": arrayUnion(cottageId)
            });

            const cottageDocRef = doc(db, "cottages", cottageId);
            await updateDoc(cottageDocRef, {
                "invitedEmails": arrayUnion(email)
            });
        }
    }
    else if (userDocs.size > 1) {
        throw new Error("More than one document found for user. Please contact support.");
    }
    else {
        //need to add to doc in uncreated (or create if hasnt been invited yet)
        throw new Error("notRegisteredError");
    }

}

async function createPendingInvites(cottageId: string, email: string): Promise<void> {

    const uncreatedDocRef = doc(db, "uncreated", email);
    await setDoc(uncreatedDocRef, {
        "pendingInvites": arrayUnion(cottageId)
    });

    const cottageDocRef = doc(db, "cottages", cottageId);
    await updateDoc(cottageDocRef, {
        "invitedEmails": arrayUnion(email)
    });

}

async function deleteCottageFromFirestore(cottageId: string) {

    //remove the cottage id from the members listed cottages
    const cottageAttendeesCollection = collection(db, "cottages", cottageId, "attendees");
    const cottageAttendeesSnapshot = await getDocs(cottageAttendeesCollection);
    for (const attendeeDoc of cottageAttendeesSnapshot.docs) {
        const userDoc = attendeeDoc.data().userDoc;
        await updateDoc(userDoc, {
            "cottageIDs": arrayRemove(cottageId)
        })
    }

    //delete the attendees collection
    const attendeesCollection = collection(db, "cottages", cottageId, "attendees");
    const attendeesSnapshot = await getDocs(attendeesCollection);
    for (const attendeeDoc of attendeesSnapshot.docs) {
        await deleteDoc(attendeeDoc.ref);
    }
    
    //delete the cars collection
    const carsCollection = collection(db, "cottages", cottageId, "cars");
    const carsSnapshot = await getDocs(carsCollection);
    for (const carDoc of carsSnapshot.docs) {
        await deleteDoc(carDoc.ref);
    }

    //delete the groceries collection
    const groceriesCollection = collection(db, "cottages", cottageId, "groceries");
    const groceriesSnapshot = await getDocs(groceriesCollection);
    for (const groceryDoc of groceriesSnapshot.docs) {
        await deleteDoc(groceryDoc.ref);
    }

    //delete the posts collection
    const postsCollection = collection(db, "cottages", cottageId, "posts");
    const postsSnapshot = await getDocs(postsCollection);
    for (const postDoc of postsSnapshot.docs) {
        await deleteDoc(postDoc.ref);
    }

    //delete the rooms collection
    const roomsCollection = collection(db, "cottages", cottageId, "rooms");
    const roomsSnapshot = await getDocs(roomsCollection);
    for (const roomDoc of roomsSnapshot.docs) {
        await deleteDoc(roomDoc.ref);
    }

    //delete the cottage document
    const cottageDoc = doc(db, "cottages", cottageId);
    deleteDoc(cottageDoc);

}

export { getCottages, createCottage, getCottageInfo, editCottageInfo, getAttendeesForCottage, getInvitedEmails, removeAttendeeFromFirestore, deleteInviteFromFirestore, sendInviteToFirebase, createPendingInvites, deleteCottageFromFirestore }