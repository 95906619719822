import './styles/PostsTab.css'
import { Avatar, Button, CircularProgress, Dialog, DialogActions, DialogTitle, Divider, IconButton, ListItemAvatar, TextField } from "@mui/material";
import { Post } from "../Models/Post";
import EmptyTab from './EmptyTab';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

const PostsTextFieldInput = {
    '& label.Mui-focused': {
      color: '#1A3B0E',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#1A3B0E',
    },
    '& .MuiInputLabel-root': {
      color: '#1A3B0E'
    },
    '& .MuiOutlinedInput-root': {
      color: '#1A3B0E',
      backgroundColor: '#A7D489',
      '& fieldset': {
        borderColor: '#1A3B0E',
      },
      '&:hover fieldset': {
        borderColor: '#1A3B0E',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1A3B0E',
      },
    },
    width: '75%',
    marginTop: '5px',
    marginBottom: '5px'
}

interface postsProps {
    posts: Post[] | null
    uploadPost: (postText: string) => Promise<void>
    canUploadPosts: boolean
    loggedInUserId: string
    deletePost: (postId: string) => Promise<void>
}

function PostsTab( { posts, uploadPost, canUploadPosts, loggedInUserId, deletePost } : postsProps ) {

    // State
    const [postText, setPostText] = useState<string>('');
    const [isUploadingPost, setIsUploadingPost] = useState<boolean>(false);
    const [deletePostOpen, setDeletePostOpen] = useState<boolean>(false);
    const [postIdToDelete, setPostIdToDelete] = useState<string | null>(null);
    // Event functions
    const postButtonClicked = () => {

        setIsUploadingPost(true);

        if (postText !== '') {
            uploadPost(postText)
            .then(() => {
                setPostText('');
                setIsUploadingPost(false);
            })
            .catch(() => {
                setIsUploadingPost(false);
            })
        }

    }

    const deleteButtonPressed = (post: Post) => {
        setPostIdToDelete(post.id);
        setDeletePostOpen(true);
    }

    const deletePostDialogClose = () => {
        setPostIdToDelete(null);
        setDeletePostOpen(false);
    }

    const confirmDeletePost = () => {
        if (postIdToDelete) {
            setDeletePostOpen(false);
            deletePost(postIdToDelete)
            .then(() => {
                setPostIdToDelete(null);
            });
        }
    }

    // View

    return (
        <div className='Poststab-container'>
            <div className='Poststab-title'>
                Posts
            </div>
            { canUploadPosts &&
                <div>
                    <TextField
                        label="Post Message"
                        multiline
                        sx={PostsTextFieldInput}
                        value={postText}
                        onChange={(e) => setPostText(e.target.value)}
                    />
                    <div>
                        { isUploadingPost ?
                            <CircularProgress color='success'/>
                            :
                            <Button
                                variant="contained"
                                sx={{
                                    margin: '10px',
                                    backgroundColor: '#1A3B0E',
                                    '&:hover': {
                                        backgroundColor: '#102408'
                                    }
                                }}
                                onClick={() => postButtonClicked()}
                                disabled={postText === ''}
                            >
                                Post
                            </Button>
                        }
                    </div>
                </div>
            }
            <Divider
                sx={{
                    marginBottom: '10px'
                }}
            >
                Posts
            </Divider>
            <div>
                {
                    posts ?
                    <div>
                        { posts.length > 0 ?
                            <div>
                                {posts.filter(function(post){ return post !== undefined }).map((item) => (
                                    <div className='Poststab-post-container'>
                                        { item.poster?.firebaseUserID === loggedInUserId &&
                                            <div className='Poststab-post-delete-icon'>
                                                <IconButton onClick={() => deleteButtonPressed(item)}>
                                                    <DeleteIcon 
                                                        sx={{ color: '#102408' }}
                                                    />
                                                </IconButton>
                                            </div>
                                        }
                                        <div className='Poststab-post-text'>
                                            {item.message}
                                        </div>
                                        <div style={{ paddingBottom: '10px' }}>
                                            <ListItemAvatar sx={{ justifyContent: "center", display: "flex" }}>
                                                <Avatar alt={item.poster.name} src="TODO-placeholder-tbd-once-pfp-implemented" />
                                            </ListItemAvatar>
                                        </div>
                                        <div>
                                            {item.poster.name}
                                        </div>
                                        <div>
                                            {item.datePosted.toLocaleString()}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            :
                            <EmptyTab labelText={'No posts...'}/>
                        }
                    </div>
                    :
                    <div>
                        <CircularProgress color='success' />
                    </div>
                }
            </div>
            <Dialog
                open={deletePostOpen}
                onClose={() => deletePostDialogClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ zIndex:'10000000000000000000' }}>
                    <DialogTitle id="alert-dialog-title">
                    {"Do you want to delete your post?"}
                    </DialogTitle>
                {/* <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This can not be undone
                    </DialogContentText>
                </DialogContent> */}
                <DialogActions>
                    <Button onClick={() => deletePostDialogClose()}>No</Button>
                    <Button onClick={() => confirmDeletePost()}>Yes</Button>
                </DialogActions>
            </Dialog>
        </div>
    )

}

export default PostsTab;